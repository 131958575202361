<template>
  <FocusTrap>
    <div id="mycard" class="card">

      <div class="card-header header-elements-inline" >
        <h5 class="card-title">Item Information</h5>
        <div class="header-elements">
          <div class="list-icons">

            <a class="list-icons-item" data-action="reload" @click="loadAll"></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>

      <div class="card-body">
        <form action="#">

          <div class="form-group row">
            <label class="col-lg-2 col-form-label">Group:</label>
            <div class="col-lg-10">
              <select ref="cmbGroup" class="form-control form-control-select2" autofocus v-if="item" v-model="item.group.id" >
                <option v-for="group in groups" v-bind:value="group.id">
                  {{ group.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-lg-2 col-form-label">Name:</label>
            <div class="col-lg-10">
              <input type="text" class="form-control" placeholder="Enter Item Name here..." maxlength="100" v-if="item" v-model="item.name">
            </div>
          </div>

          <!--<div class="form-group row">-->
            <!--<label class="col-lg-2 col-form-label">Category:</label>-->
            <!--<div class="col-lg-4">-->
              <!--<select class="form-control form-control-select2" v-if="item" v-model="item.category.id">-->
                <!--<option v-for="category in categories" v-bind:value="category.id">-->
                  <!--{{ category.name }}-->
                <!--</option>-->
              <!--</select>-->
            <!--</div>-->

            <!--<label class="col-lg-1 col-form-label">Brand:</label>-->
            <!--<div class="col-lg-5">-->
              <!--<select class="form-control form-control-select2" v-if="item" v-model="item.brand.id">-->
                <!--<option v-for="brand in brands" v-bind:value="brand.id">-->
                  <!--{{ brand.name }}-->
                <!--</option>-->
              <!--</select>-->
            <!--</div>-->
          <!--</div>-->

          <div class="form-group row">
            <label class="col-lg-2 col-form-label">Unit of measure:</label>
            <div class="col-lg-4">
              <select class="form-control form-control-select2" v-if="item" v-model="item.unit.id">
                <option value=1>Nos</option>
                <option value=2>Kgs</option>
                <option value=3>Mtrs</option>
                <option value=4>Ltrs</option>
              </select>
            </div>

            <label class="col-lg-1 col-form-label">Min Qty:</label>
            <div class="col-lg-2">
              <input type="number" class="form-control text-right" placeholder="min stock qty" min="0" v-if="item" v-model="item.min_qty">
            </div>

            <label class="col-lg-1 col-form-label">Max Qty:</label>
            <div class="col-lg-2">
              <input type="number" class="form-control text-right" placeholder="max stock qty" min="0" v-if="item" v-model="item.max_qty">
            </div>
          </div>


          <div class="form-group row">
            <label class="col-lg-2 col-form-label">Ledger: Purchase</label>
            <div class="col-lg-4">
              <select class="form-control form-control-select2" >
                <option value="1">-</option>
                <option v-for="ledger in purchledgers" v-bind:value="ledger.id">
                  {{ ledger.name }}
                </option>
              </select>
            </div>

            <label class="col-lg-1 col-form-label">Sale </label>
            <div class="col-lg-5">
              <select class="form-control form-control-select2" >
                <option value="1">-</option>
                <option v-for="ledger in saleledgers" v-bind:value="ledger.id">
                  {{ ledger.name }}
                </option>
              </select>
            </div>
          </div>

        </form>
      </div>

      <div class="card-footer">
        <div class="text-right" >
          <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
        </div>
      </div>
    </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
export default {
  name: 'ItemForm',
  data () {
    return {
      item: {},
      groups: [],
      categories: [],
      brands:[],
      purchledgers:[],
      saleledgers:[]
    }
  },
  props: {
    myitem: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":1,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"subunit_id":1,"conv_fact":1,"gstrate":0,"itcrate":100,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"subunit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0},"brand":{"id":1,"type":1,"name":"","status":"Active"}}')
    }
  },
  beforeMount () {
    this.item = this.myitem; // save props data to itself's data and deal with tms
  },
  component: {

  },
  created () {
    this.$data.item = JSON.parse('{"id":0,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"subunit_id":1,"conv_fact":1,"gstrate":0,"itcrate":100,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"subunit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0},"brand":{"id":1,"type":1,"name":"","status":"Active"}}')
  },
  mounted () {
    const self = this;
    //$('.form-control-select2').select2()

    self.loadGroup();
    self.loadBrands();
    self.loadCategories();
    self.loadLedgers();

    self.$refs.cmbGroup.focus();
  },
  methods: {
    closeWindow(){
      this.$emit("item_window_closed");
    },
    clear(){
      this.$data.itemgroup = JSON.parse('{"id":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":100,"unit":{"id":0,"name":"","digits":0,"sys_obj":false},"subunit":{"id":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"has_bom":false,"sys_obj":false}')
    },
    loadAll(){
      loadGroup();
      loadBrands();
      loadCategories();
      loadLedgers();
    },
    loadGroup(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      fetch(`${process.env.VUE_APP_ROOT_API}api/itemgroups`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$data.groups = resp.data;
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });

    },
    loadBrands(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      fetch(`${process.env.VUE_APP_ROOT_API}api/brands`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$data.brands = resp.data;
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });

    },
    loadCategories(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      fetch(`${process.env.VUE_APP_ROOT_API}api/categories`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$data.categories = resp.data;
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });
    },
    loadLedgers(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.purchledgers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/party_ledgers`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if(_.isArray(resp.data)){
            self.$data.purchledgers  = resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.message, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });

      self.$data.saleledgers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/sale_ledgers`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if(_.isArray(resp.data)){
            self.$data.saleledgers  = resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.message, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });
    },
    saveDocument(){
      const self = this;

      try {

      //self.$data.item.unit.id = parseInt(self.$data.item.unit.id);
      // self.$data.item.category.id = 1; //parseInt(self.$data.item.category.id);
      // self.$data.item.brand.id = 1; //parseInt(self.$data.item.brand.id);

        self.item.group_id = parseInt(self.item.group.id);
        self.item.unit_id = parseInt(self.item.unit.id);
        self.$data.item.min_qty = parseFloat(self.$data.item.min_qty);
        self.$data.item.max_qty = parseFloat(self.$data.item.max_qty);

      if(self.$data.item.name.trim().length < 1){
        alert("Invalid Name");
        return
      }


      const requestOptions = {
        method:  (self.$data.item.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.item)
      };


        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        fetch(`${process.env.VUE_APP_ROOT_API}api/item`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ title: "Success!", text: resp.msg, type: "success" });
            self.item = JSON.parse('{"id":0,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"subunit_id":1,"conv_fact":1,"gstrate":0,"itcrate":100,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"subunit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0},"brand":{"id":1,"type":1,"name":"","status":"Active"}}');
            self.closeWindow();
            self.$emit('item_saved', self.item);
          } else {
            swal({ title: "Oops!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        }).finally(()=>{
          $('#mycard').unblock();
        });
      }catch (e) {
        alert(e);
      }
    }
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
