<template>
  <FocusTrap>
    <div class="card">

    <div class="card-header bg-transparent header-elements-sm-inline py-sm-0">

      <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Item's List</h5>

      <div class="header-elements">
        <form action="#">
          <div class="form-group-feedback form-group-feedback-right">
            <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
            <div class="form-control-feedback">
              <i class="icon-search4 font-size-base text-muted"></i>
            </div>
          </div>
        </form>

      </div>
    </div>


    <div class="table-responsive">
      <table id="mytable"
             class="table table-bordered table-condensed table-columned"
             data-search="false"
             data-show-refresh="false"
             data-show-columns="false"
             data-pagination="false"
             data-page-list="[10, 25, 50, 100,ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-formatter="runningFormatter" data-width="75" >S.No</th>
          <th data-field="id" data-width="75"  data-class="d-none">ID</th>
          <th data-field="name" data-sortable="true">Name</th>
          <th data-field="group.id" data-class="d-none" data-sortable="true">Group</th>
          <th data-field="group.name" data-sortable="true">Group</th>

          <th data-field="unit.name" data-sortable="true">Unit</th>
          <th data-field="group.hsn" data-sortable="true">HSN</th>
          <th data-field="group.gstrate" data-sortable="true">GST Rate</th>
          <th data-field="group.itcrate" data-sortable="true">ITC</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: blue"></i>Refresh</a>
<!--        <a href="#" class="dropdown-item" ><i class="icon-link" ></i>Ledger Map</a>-->
        <a href="#" class="dropdown-item" ><i class="icon-pencil7" style="color: #2d7330"></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" ><i class="icon-blocked" style="color: orangered"></i>Remove</a>
      </div>
    </div>
    <!-- End of Context Menu -->


    <vmodal name="item_modal_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="70%" :height="380" @click="showModal" :draggable="true">
      <ItemForm v-bind:myitem="item" v-on:item_window_closed="closeModal" v-on:item_saved="loadData" ></ItemForm>
    </vmodal>

    <vmodal name="ledger_modal_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="80%" height="92%" @click="showModal" :draggable="true">
      <LedgerMapForm :groupid="groupid" :groupname="groupname" v-on:closeWindow="closeLedgerMapWindow" ></LedgerMapForm>
    </vmodal>
    <!-- End of Modal Window -->

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->

  </div>
  </FocusTrap>
</template>

<script>
  import ItemForm from './ItemForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import LedgerMapForm from './GroupLedgerMapForm.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'ItemView',
    components: {
      ItemForm,
      LedgerMapForm,
      FabButton,
      moment
    },
    data () {
      return {
        readonly: false,
        item: JSON.parse('{"id":0,"status":"Active","type":"","name":"","group_id":1,"unit_id":2,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":2,"max_qty":3,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":2,"type":0,"name":"Kgs","status":"Active","digits":3},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":0,"name":"","status":"Active"}}'),
        mytable: {},
        units: [],
        groupid:"",
        groupname:"",
        groupcode:Number,
      }
    },
    created () {

    },
    mounted () {
      const self = this;
      this.$data.mytable = $('#mytable');
      this.$data.mytable.bootstrapTable();

      $('[data-toggle="popover"]').popover();
      $('#mytable').contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {

          var id = $(row.children('*')[0]).text();

          if ($(e.target).text() === "Refresh") {
            self.loadData();
          }else if($(e.target).text() === 'Modify'){
            self.modifyDocument(id);
          }else if($(e.target).text() == 'Remove'){
            self.removeDocument(id);
          }else if($(e.target).text() === 'Ledger Map'){
            self.showLedgerMapWindow("", $(row.children('*')[4]).text(), $(row.children('*')[5]).text());
          }

        }
      });

      $('.select-search').select2();

      $('#txtsearch').keyup(function () {
        let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#mytable>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });


      $('#item_modal_window').on('shown.bs.modal', function () {
        $('#txtname').trigger('focus');
      });

      $('#item_modal_window').on('hide.bs.modal', function () {
        self.clear();
      });

      $('[data-action=remove]').click(function (e) {
        $('#item_modal_window').modal('hide');
      });

      self.loadData();


    },
    methods: {
      closeModal() {
        this.$modal.hide('item_modal_window')
      },
      beforeOpen(){
        console.log('beforeOpen');
      },
      beforeClose(){
        this.loadData();
      },
      showModal () {
        this.item= JSON.parse('{"id":0,"status":"Active","type":"","name":"","group_id":1,"unit_id":2,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":2,"max_qty":3,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":2,"type":0,"name":"Kgs","status":"Active","digits":3},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":0,"name":"","status":"Active"}}');
        this.$modal.show('item_modal_window');
      },
      clear(){
        this.item= JSON.parse('{"id":0,"status":"Active","type":"","name":"","group_id":1,"unit_id":2,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":2,"max_qty":3,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":2,"type":0,"name":"Kgs","status":"Active","digits":3},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":0,"name":"","status":"Active"}}');
      },
      showLedgerMapWindow(id,code,groupname){
        this.$data.groupid = id;
        this.$data.groupcode = code;
        this.$data.groupname = groupname;

        this.$modal.show('ledger_modal_window');
      },
      closeLedgerMapWindow(id){
        this.$data.groupname = "";
        this.$data.groupid = "";
        this.$modal.hide('ledger_modal_window');
      },
      loadMapping(code){
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.blockCard();

        this.$data.mapping = JSON.parse('{"id":"","p301":1,"p302":1,"p303":1,"p304":1,"p305":1,"p306":1,"p307":1,"s404":1,"s405":1,"s406":1}');

        fetch(`${process.env.VUE_APP_ROOT_API}api/itemgroup/ldgrmap/${code}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            self.$data.mapping = resp.data;
          } else {
            // swal ( { title: "Oh noes!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oops!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $('#ledgermapcard').unblock();
        });

      },
      modifyDocument (id) {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.item = {};
        fetch(`${process.env.VUE_APP_ROOT_API}api/item/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            self.$data.item = resp.data;
            self.$modal.show('item_modal_window');
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text:  err.toString() , type:  "error"} );
        });
      },
      removeDocument (id) {
        const self = this;

        self.$data.item.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.item)
        };

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          timer: 3000,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete tms!'
        }).then((result) => {
          if (result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}api/item`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('item_modal_window')
              self.loadData();
              swal(
                'Deleted!',
                'Your request has been processed',
                'success'
              );
            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });

      },
      loadData () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $(self.$data.mytable).block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.mytable.bootstrapTable('load',[]);

        fetch(`${process.env.VUE_APP_ROOT_API}api/items`,requestOptions).then(userService.handleResponse).then(function (resp) {

          if(resp.ok) {
            if(!_.isNull(resp.data)){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $(self.$data.mytable).unblock();
        });

      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
